import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import axios from "axios"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import { CONTENT_SIZE } from "style/size"

import { Layout } from "components/Layout"
import { ContentPortableText } from "components/blockContent/ContentPortableText"

import { Button } from "components/interactable/Button"

import { Title } from "components/text/Title"
import { Text } from "components/text/Text"
import { Italics } from "components/text/Italics"
import { ContactFieldsGrid } from "../components/interactable/ContactFieldsGrid"
import { getLinkPropsFromRawData } from "../linkFunctions"

const Content = styled.div`
	padding: 0 24px;
	margin: 0 auto;
	margin-bottom: ${props => (props.noMargin ? "0" : "40px")};
	max-width: ${CONTENT_SIZE}px;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: stretch;
`

const Form = styled.form`
	display: flex;
	flex-direction: column;
	align-items: stretch;
`

const Row = styled.div`
	display: flex;
	flex-direction: column-reverse;
	> *:not(:last-child) {
		margin-bottom: 24px;
	}
	@media screen and (min-width: 800px) {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 64px;
		margin-bottom: 24px;
	}
`

/**
 * Teams needs to be a template since the slug is dynamic
 * @param {*} param0
 * @returns
 */
const TemplateOrder = ({ data: { sanityOrder } }) => {
	console.log({ sanityOrder })

	const { register, handleSubmit } = useForm()

	const { title, info, button_text, success_url, _rawContent, meta, image } =
		sanityOrder || {}

	const onSubmit = data => {
		console.log("onSubmit", { data })
		const { firstName, lastName, email, phone } = data || {}
		if (!firstName) {
			return toast.error("Du må oppgi fornavn")
		}
		if (!lastName) {
			return toast.error("Du må oppgi etternavn")
		}
		if (!email || !phone) {
			return toast.error("Du må oppgi epost eller telefon")
		}
		data.type = "order"
		toast("Sender epost...")
		axios
			.post("https://utils.wayscloud.no/skullerudcontact.php", data)
			.then(response => {
				console.log("Success", { response })
				toast.success("Bestillingen er gjennomført!")
				const url = getLinkPropsFromRawData(success_url?._rawData)?.to
				if (url) navigate(url)
			})
			.catch(response => {
				console.log("Failure", { response })
				toast.error("Auda, noe gikk galt. Prøv igjen!")
			})
	}

	return (
		<Layout meta={meta}>
			<ToastContainer />
			<Content>
				<Title>{title}</Title>
				<Row>
					<ContentPortableText blocks={_rawContent} />
					<Image
						fluid={image?.image?.asset?.fluid}
						alt={image?.alt}
					/>
				</Row>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<ContactFieldsGrid register={register} />
					<Text center>
						<Italics>{info}</Italics>
					</Text>
					<Button center>{button_text}</Button>
				</Form>
			</Content>
		</Layout>
	)
}

export const query = graphql`
	query GetOrder {
		sanityOrder {
			title
			info
			button_text
			success_url {
				label
				_rawData(resolveReferences: { maxDepth: 10 })
			}
			_rawContent
			meta {
				title
				description
			}
			image {
				alt
				image {
					asset {
						fluid {
							srcWebp
							srcSetWebp
							srcSet
							src
							sizes
							base64
							aspectRatio
						}
					}
				}
			}
		}
	}
`

export default TemplateOrder
